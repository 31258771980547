export const navData = [
  {
    name: "À Propos",
    href: "#why",
  },
  {
    name: "Nos Avantages",
    href: "#advantages",
  },
  {
    name: "Nos Services",
    href: "#services",
  },
  {
    name: "Contact",
    href: "#contact",
  },
];

export const currency = [
  {
    name: "Expertise et Professionnalisme",
    description:
      "Forts de notre longue expérience, nos techniciens certifiés interviennent sur tous types de véhicules, qu'il s'agisse de voitures, de camions ou de véhicules utilitaires. Nous utilisons les dernières technologies pour assurer des interventions précises et de qualité.",
  },
  {
    name: "Service Client Inégalé",
    description:
      "La satisfaction de nos clients est au cœur de notre métier. Nous offrons un service personnalisé, adapté à chaque situation, avec une attention particulière aux détails. Notre équipe est à votre écoute pour répondre à toutes vos questions et vous accompagner tout au long du processus.",
  },
  {
    name: "Franchise Offerte",
    description:
      "Nous couvrons la totalité de votre franchise, vous garantissant une tranquillité d'esprit lors du remplacement de votre pare-brise.",
  },
];

export const currency1 = [
  {
    name: "Service à Domicile",
    description:
      "Pour votre confort, nous nous déplaçons directement à votre domicile ou sur votre lieu de travail pour remplacer votre pare-brise, vous évitant ainsi toute perte de temps.",
  },
  {
    name: "Partenariat avec les Assurances",
    description:
      "Nous collaborons étroitement avec de nombreuses compagnies d'assurance pour simplifier la gestion de vos sinistres. De la vérification du bris de glace à la facturation, nous prenons en charge toutes les démarches administratives.",
  },
  {
    name: "Réactivité et Disponibilité",
    description:
      " Nous comprenons l'importance de retrouver votre véhicule rapidement. C'est pourquoi nous proposons des services de remplacement avec des délais de livraison express. En moins de 24 heures, nous vous fournissons les vitrages nécessaires pour reprendre la route en toute sécurité.",
  },
];
